// import React, { useState } from "react";
// import { Dropdown } from "primereact/dropdown";
// import { InputMask } from "primereact/inputmask";
// import { InputText } from "primereact/inputtext";

// const PhoneNumber = ({id,onInputChange}) => {
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const countries = [
//     { label: "India (+91)", value: "+91" },
//     { label: "USA (+1)", value: "+1" },
//     { label: "UK (+44)", value: "+44" },
//     { label: "Canada (+1)", value: "+1" },
//   ];
//   const onCountryChange = (e) => {
//     setSelectedCountry(e.value);
//     setPhoneNumber("");
//   };

//   const getMask = () => {
//     switch (selectedCountry) {
//       case "+91":
//         return "99999-99999";
//       case "+1":
//         return "(999) 999-9999";
//       case "+44":
//         return "9999 999 999";
//       default:
//         return "";
//     }
//   };

//   return (
//       <div className="flex justify-content-center mt-4">
//       <div
//         style={{
//           width: "95%",
//           background: "#fff",
//           borderRadius: "12px",
//           border: "1px solid #11409E",
//         }}
//       >
//         <div className="flex justify-content-center">
//           <div
//             className="flex align-items-center justify-content-center m-1"
//             style={{
//               width: "100%",
//               height: "4rem",
//               background: "#fff",
//             }}
//           >
//             <img
//               src="Vectorphone.png"
//               alt="logo"
//               className=" mr-2"
//               style={{ width: "30px" }}
//             />
//             <InputText
//               placeholder="Enter Question here..."
//               style={{ width: "90%", border: "none" }}
//                onChange={(e) => onInputChange(id, e.target.value)}
//             />
//           </div>
//         </div>

//         <div style={{ marginLeft: "4%", marginRight:"10px" }}>
//           <div
//             style={{
//               height: "4rem",
//               background: "#fff",
//             }}
//           >
//          <Dropdown
//             value={selectedCountry}
//             options={countries}
//             onChange={onCountryChange}
//             placeholder="Select"
//             optionLabel="label"
//             style={{maxWidth:"20%", minWidth:"20%", border:"none"}}
//           />
//           <InputMask
//             mask={getMask()}
//             value={phoneNumber}
//             onChange={(e) => setPhoneNumber(e.target.value)}
//             placeholder="Enter PhoneNumber Question here..."
//             style={{ width: "70%", border: "none" }}
//           />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PhoneNumber;



import React from "react";
import { InputText } from "primereact/inputtext";

const PhoneNumber = ({id,onInputChange,txtQuestion}) => {

  return (
    <div className="single-line flex justify-content-center">
      <div
        className="flex align-items-center justify-content-center mt-4"
        style={{
          width: "95%",
          height: "4rem",
          background: "#fff",
          borderRadius: "12px",
          border: "1px solid #11409E",
        }}
      >
        <img src="Vectorphone.png" alt="logo" className=" mr-2" />
        <InputText
          placeholder="Enter Phone Number Question here..."
          style={{ width: "90%", border: "none" }}
          onChange={(e) => onInputChange(id, e.target.value)}
          value={txtQuestion}
        />
      </div>
    </div>
  );
};

export default PhoneNumber;
