// import React from 'react'
// import "./App.css"
// import "primeicons/primeicons.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/primereact.css";
// import "primeflex/primeflex.css";
// import Login from './Layout/Login';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Projects from './Components/Projects';
// import CreateProject from './Components/Create Project/CreateProject';
// import Forms from './Components/Forms/Forms';


// const App = () => {
//   return (
//       <BrowserRouter>
//         <Routes>
//              <Route path='/' element={<Login/>}/>
//              <Route path='/projects' element={<Projects/>}/>
//              <Route path='/createProject' element={<CreateProject/>}/>
//              <Route path='/forms' element={<Forms/>}/>


//         </Routes>
//       </BrowserRouter>
//   )
// }

// export default App


import React, { useEffect } from 'react'
import "./App.css"
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import Login from './Layout/Login';
import "./Layout/sidebar.css"
import { BrowserRouter, Route, Routes, useNavigate  } from 'react-router-dom';
import Projects from './Components/Projects';
import Forms from './Components/Forms/Forms';
import CreateForms from './Components/Create Forms/CreateForms';
import PreviewPage from './Components/Create Forms/PreviewPage';
import NotFound from './Layout/NotFound';
import FormDetails from './Components/Create Forms/UserForms/FormDetails';


const App = () => {
  const userRole = sessionStorage.getItem('userRole');
  const hasSessionId = !!sessionStorage.getItem('sessionId');
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasSessionId) {
      navigate('/');
    }
  }, [hasSessionId, navigate]);

  const isAdmin = userRole === '1';
  return (
    // <div>
    //   <div>
    //     <Routes>
    //          <Route path='/' element={<Login/>}/>
    //          <Route path='/projects' element={<Projects/>}/>
    //          <Route path="/forms/:projectId/:projectName" element={<Forms />} />
    //          <Route path="/createForm" element={<CreateForms/>}/>
    //          <Route path="/preview" element={<PreviewPage/>}/>
    //     </Routes>
    //   </div>
    // </div>
    <div>
    <div>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/projects' element={<Projects/>}/>
        {isAdmin && (
          <>
            <Route path='/projects' element={<Projects/>}/>
            <Route path="/forms/:projectId/:projectName" element={<Forms />} />
            <Route path="/createForm" element={<CreateForms />} />
          </>
        )}
        {userRole === '2' && (
            <>
              {/* Add routes specific to userRole 2 */}
              <Route path="/form/:formName" element={<FormDetails/>} />
            </>
          )}

          {/* Additional routes for userRole 3 */}
          {userRole === '3' && (
            <>
              {/* Add routes specific to userRole 3 */}
            </>
          )}
          {userRole === '4' && (
            <>
              {/* Add routes specific to userRole 3 */}
            </>
          )}
          <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  </div>
  )
}

export default App
