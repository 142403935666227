import React from 'react'
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";

const YesNoRightBarContent = ({
    id,
    inputSwitchState,
    handleInputChange,
    handleDelete,
    handleDuplicate
}) => {

    
      return (
        <div>
          <TabView>
            <TabPanel header="Validation">
              <div className="flex justify-content-between align-items-center">
                Mandatory{" "}
                <InputSwitch
                  checked={inputSwitchState}
                  onChange={(e) => handleInputChange(id, e)}
                />
              </div>
              <br></br>


           <div className="flex justify-content-around mt-5">
           <Button className="p-2" severity="success" onClick={() => handleDuplicate(id)}>Duplicate Question</Button>
           <Button className="p-2 ml-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>

           </div>
            </TabPanel>
            <TabPanel header="Skip Logic">
              <p className="m-0">Skip Logic</p>
            </TabPanel>
          </TabView>
        </div>
   )
}

export default YesNoRightBarContent