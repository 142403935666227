import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import Header from "../../Layout/Header";
import Dashboard from './Form Tabs/Dashboard';
import TeamMembers from "./Form Tabs/TeamMembers";
import FormsTable from "./Form Tabs/FormsTable";
import Roles from "./Form Tabs/Roles";

const Forms = () => {
  const fldNameOfTeamMember = sessionStorage.getItem('fldNameOfTeamMember');
  const { projectId, projectName } = useParams();
  console.log('Project ID:', projectId);
  console.log('Project Name:', projectName);

  const navigate = useNavigate();
  const items = [{ label: projectName }];
  const home = {
    label: "Home",
    command: () => {
      navigate("/projects");
    },
  };

  return (
    <div style={{ margin: "20px" }}>
      <Header fldNameOfTeamMember={fldNameOfTeamMember}/>
      <div style={{ margin: "1pc" }}>
        <BreadCrumb home={home} model={items}/>
        <h1 style={{ fontSize: "28px" }}>{projectName}</h1>

        <TabView>
                <TabPanel header="Dashboard">
                    <div className="m-0">
                      <Dashboard/> </div>
                </TabPanel>
                <TabPanel header="Roles">
                    <p className="m-0">
                        <Roles projectName={projectName} projectId={projectId}/> </p>
                </TabPanel>
                <TabPanel header="Team Members">
                    <p className="m-0">
                        <TeamMembers projectName={projectName} projectId={projectId}/> </p>
                </TabPanel>
                <TabPanel header="Forms">
                <p className="m-0">
                        <FormsTable projectName={projectName} projectId={projectId}/> </p>
                </TabPanel>

                <TabPanel header="Reports">
                    <p className="m-0">
                        Tab6 </p>
                </TabPanel>
                <TabPanel header="Settings">
                    <p className="m-0">
                        Settings </p>
                </TabPanel>
            </TabView>
      </div>
    </div>
  );
};

export default Forms;
