import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

const PreviewPage = ({
  selectedItems,
  singleLineTextValues,
  multiLineTextValues,
  numericTextValues,
  checkboxTextValues,
  dropdownTextValues,
  yesNoTextValues,
  dateTextValues,
  emailTextValues,
  phoneNumberTextValues,
  labelTextValues,
  inputSwitchStates,
  checkboxOptions,
  dropdownOptions,
  onCheckboxInputChange,
  onCheckboxOptionsChange,
  formTitle,
  onHide1,
  setCheckboxTextValues
}) => {
  console.log("selectedItems", selectedItems);
  console.log("singleLineTextValues", singleLineTextValues);
  console.log("multiLineTextValues", multiLineTextValues);
  console.log("inputSwitchStates", inputSwitchStates);

  console.log("Checkbox Options:", checkboxOptions);
  console.log('Dropdown Options:', dropdownOptions);
  


  const yesno = [
    { name: "Yes", key: "Y" },
    { name: "No", key: "N" },
  ];
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [date, setDate] = useState(null);
  // const [checkboxState, setCheckboxState] = useState({});

  // const handleCheckboxChange = (id, option, isChecked) => {
  //   setCheckboxState((prevState) => {
  //     const newState = { ...prevState };
  //     newState[id] = newState[id] || {};
  //     newState[id][option] = isChecked;
  //     return newState;
  //   });
  // };

  const [checkboxState, setCheckboxState] = useState({});

  const handleCheckboxChange = (item, index, e) => {
    const newCheckboxState = { ...checkboxState };
    newCheckboxState[`${item.id}_${index}`] = e.checked;
    setCheckboxState(newCheckboxState);
    const isVisible = e.checked || !inputSwitchStates[item.id];
    const newTextValues = { ...checkboxTextValues, [item.id]: isVisible ? checkboxTextValues[item.id] : "" };
    onCheckboxInputChange({ id: item.id, values: Object.values(newCheckboxState) });
    setCheckboxTextValues(newTextValues);
  };

  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});

  const handleDropdownChange = (id, value) => {
    setSelectedDropdownValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  return (
    <div className="flex justify-content-center" >
    <form style={{width:"70%", border:"solid 1px #dee2e6", borderRadius:"10px"}}>
    <div style={{margin:"15px 15px 25px 15px"}}>
    <h1 className="mt-0" style={{ fontSize: "28px" }}>{formTitle}</h1>
    <div>
      {selectedItems.map((item, index) => (
        <div key={item.id}>
          {item.component.type.name === "SingleLine" && (
            <div className="mt-4">
              <label>
                {index + 1}. {singleLineTextValues && singleLineTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>  
              <div className="flex justify-content-center">
              <InputText className="mt-1 userForm" placeholder={singleLineTextValues[item.id]}/>
              </div>
            </div>
          )}

          {item.component.type.name === "MultiLine" && (
            <div className="mt-4">
              <label>
                {index + 1}.{multiLineTextValues && multiLineTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              <div className="flex justify-content-center">
              <InputTextarea className="mt-1 userForm" placeholder={multiLineTextValues[item.id]} />
              </div>
            </div>
          )}

          {item.component.type.name === "Numeric" && (
            <div className="mt-4">
              <label>
                {index + 1}. {numericTextValues && numericTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              <div className="flex justify-content-center">
              <InputText keyfilter="int" className="mt-1 userForm" placeholder={numericTextValues[item.id]}/>
              </div>
            </div>
          )}

          {item.component.type.name === "Checkbox" && (
            <div className="mt-4">
              <label>
                {index + 1}. {checkboxTextValues && checkboxTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              {checkboxOptions[item.id] ? (
                checkboxOptions[item.id].split("$$").map((option, index) => (
                  <div key={index} className="mt-1" style={{display:"flex", alignItems:"center"}}>
                    <Checkbox className=" ml-3 mr-2"
                      id={`cb_${item.id}_${index}`}
                      value={option}
                      // onInputChange={(value) =>
                      //   onCheckboxInputChange(item.id, value)
                      // }
                      // onOptionsChange={(id, options) =>
                      //   onCheckboxOptionsChange(id, options)
                      // }
                      checked={checkboxState[`${item.id}_${index}`] || false}
                onChange={(e) => handleCheckboxChange(item, index, e)}
                onOptionsChange={(options) => onCheckboxOptionsChange(item.id, options)}
                      txtQuestion={checkboxTextValues[item.id]}
                    />
                    <label htmlFor={`cb_${item.id}_${index}`}>{option}</label>
                  </div>
                ))
              ) : (
                <div>No options available.</div>
              )}
            </div>
          )}

          {item.component.type.name === "Dropdowns" && (
            <div className="mt-4">
              <label>
                {index + 1}. {dropdownTextValues && dropdownTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              {/* {Array.isArray(dropdownOptions[item.id]) ? (
                <div className="flex justify-content-center">
                <Dropdown className="mt-1 userForm"
                  options={dropdownOptions[item.id].map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  placeholder="Select an option"
                  value={selectedDropdownValues[item.id]}
                  onChange={(e) => handleDropdownChange(item.id, e.value)}
                />
                </div>
              ) : (
                <div>No options available.</div>
              )} */}
             {Array.isArray(dropdownOptions[item.id + '_split']) ? (
  <div className="flex justify-content-center">
    <Dropdown
      className="mt-1 userForm"
      options={dropdownOptions[item.id + '_split'].map((option) => ({
        label: option,
        value: option,
      }))}
      placeholder="Select an option"
      value={selectedDropdownValues[item.id]}
      onChange={(e) => handleDropdownChange(item.id, e.value)}
    />
  </div>
) : (
  <div>No options available.</div>
)}
            </div>
          )}

          {item.component.type.name === "YesNo" && (
            <div className="mt-4">
              <label>
                {index + 1}. {yesNoTextValues && yesNoTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              {yesno.map((category) => {
                return (
                  <div key={category.key} className="mt-1" style={{display:"flex", alignItems:"center"}}>
                    <RadioButton className=" ml-3 mr-2"
                      inputId={category.key}
                      name="category"
                      value={category}
                      onChange={(e) => setSelectedCategory(e.value)}
                      checked={selectedCategory.key === category.key}
                    />
                    <label htmlFor={category.key} className="ml-2">
                      {category.name}
                    </label>
                  </div>
                );
              })}
            </div>
          )}

          {item.component.type.name === "Date" && (
            <div className="mt-4">
              <label>
                {index + 1}. {dateTextValues && dateTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              <div className="flex justify-content-center">
              <Calendar className="mt-1 userForm"
              placeholder={dateTextValues[item.id]}
                value={date}
                onChange={(e) => setDate(e.value)}
                showIcon
              />
              </div>
            </div>
          )}
          {item.component.type.name === "Email" && (
            <div className="mt-4">
              <label>
                {index + 1}. {emailTextValues && emailTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              <div className="flex justify-content-center">
              <InputText keyfilter="email" type="email" className="mt-1 userForm" placeholder={emailTextValues[item.id]}/>
              </div>
            </div>
          )}

          {item.component.type.name === "PhoneNumber" && (
            <div className="mt-4">
              <label>
                {index + 1}.{" "}
                {phoneNumberTextValues && phoneNumberTextValues[item.id]}
                {inputSwitchStates && inputSwitchStates[item.id] && (
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                )}
              </label>
              <div className="flex justify-content-center">
              <InputText keyfilter="int" className="mt-1 userForm" placeholder={phoneNumberTextValues[item.id]}/>
              </div>
            </div>
          )}

          {item.component.type.name === "Label" && (
            <div className="mt-4">
              <label>
                {index + 1}. {labelTextValues && labelTextValues[item.id]}
              </label>
            </div>
          )}
        </div>
      ))}
    </div>
    </div>
    {/* <div style={{display: "flex", justifyContent:"center", margin:"25px 1.5% 25px 0px"}}>
        <Button className="formbutton" label="Close" onClick={onHide1}/>
      </div> */}
    </form>
    </div>
  );
};

export default PreviewPage;
