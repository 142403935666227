import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
const NumericRightBarContent = ({
  id,
  inputSwitchState,
  inputSwitchState1,
  inputSwitchState2,
  dropdownState,
  valueState,
  textareaState,
  handleInputChange,
  handleInputChange1,
  handleInputChange2,
  handleDropdownChange,
  handleValueChange,
  handleTextareaChange,
  handleDelete,
  handleDuplicate
}) => {;
  return (

    <div>
      <TabView>
        <TabPanel header="Validation">
          <div className="flex justify-content-between align-items-center">
            Mandatory{" "}
            <InputSwitch
              checked={inputSwitchState}
              onChange={(e) => handleInputChange(id, e)}
            />
          </div>

          <div className="flex justify-content-between align-items-center mt-3">
            Use this as identifier data{" "}
            <InputSwitch
              checked={inputSwitchState1}
              onChange={(e) => handleInputChange1(id, e)}
            />
          </div>

          <div className="flex justify-content-between align-items-center mt-3">
            Use the answer as title{" "}
            <InputSwitch
              checked={inputSwitchState2}
              onChange={(e) => handleInputChange2(id, e)}
            />
          </div>

          <br></br>
          <div className="flex justify-content-between align-items-center mt-6">
            Min. characters{" "}
            <InputText keyfilter="int"
              style={{ height: "40px", width: "60%" }}
              value={valueState}
              onChange={(e) => handleValueChange(id, e)}
              placeholder="Enter text here"
            />
          </div> 

          <div className="flex justify-content-between align-items-center mt-3">
            Max. characters{" "}
            <InputText keyfilter="int"
              style={{ height: "40px", width: "60%" }}
              value={textareaState}
              onChange={(e) => handleTextareaChange(id, e)}
              placeholder="Enter text here"
            />
          </div>

       <div className="flex justify-content-between mt-5">
       <Button className="p-2" severity="success" onClick={() => handleDuplicate(id)}>Duplicate Question</Button>
       <Button className="p-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>

       </div>
        </TabPanel>
        <TabPanel header="Skip Logic">
          <p className="m-0">Skip Logic</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default NumericRightBarContent;
