// import { InputText } from "primereact/inputtext";
// import React, { useRef, useState } from "react";
// import { FileUpload } from "primereact/fileupload";
// import { ProgressBar } from "primereact/progressbar";
// import { Button } from "primereact/button";
// import { Tooltip } from "primereact/tooltip";
// import { Tag } from "primereact/tag";

// const FileUploads = () => {
//   const [totalSize, setTotalSize] = useState(0);
//   const fileUploadRef = useRef(null);

//   const onTemplateSelect = (e) => {
//     let _totalSize = totalSize;
//     let files = e.files;

//     Object.keys(files).forEach((key) => {
//       _totalSize += files[key].size || 0;
//     });

//     setTotalSize(_totalSize);
//   };

//   const onTemplateRemove = (file, callback) => {
//     setTotalSize(totalSize - file.size);
//     callback();
//   };

//   const onTemplateClear = () => {
//     setTotalSize(0);
//   };

//   const headerTemplate = (options) => {
//     const { className, chooseButton, cancelButton } = options;
//     const value = totalSize / 50000;
//     const formatedValue =
//       fileUploadRef && fileUploadRef.current
//         ? fileUploadRef.current.formatSize(totalSize)
//         : "0 B";

//     return (
//       <div
//         className={className}
//         style={{
//           backgroundColor: "transparent",
//           display: "flex",
//           alignItems: "center",
//           padding:"1rem"
//         }}
//       >
//         {chooseButton}
//         {cancelButton}
//         <div className="flex align-items-center gap-3 ml-auto">
//           <span>{formatedValue} / 5 MB</span>
//           <ProgressBar
//             value={value}
//             showValue={false}
//             style={{ width: "10rem", height: "12px" }}
//           ></ProgressBar>
//         </div>
//       </div>
//     );
//   };

//   const itemTemplate = (file, props) => {
//     return (
//       <div className="flex align-items-center flex-wrap">
//         <div className="flex align-items-center" style={{ width: "40%" }}>
//           {/* <img
//             alt={file.name}
//             role="presentation"
//             src={file.objectURL}
//             width={100}
//           /> */}
//           <span className="flex flex-column text-left ml-3">
//             {file.name}
//             <small>{new Date().toLocaleDateString()}</small>
//           </span>
//         </div>
//         <Tag
//           value={props.formatSize}
//           severity="warning"
//           className="px-3 py-2"
//         />
//         <Button
//           type="button"
//           icon="pi pi-times"
//           className="p-button-outlined p-button-rounded p-button-danger ml-auto"
//           onClick={() => onTemplateRemove(file, props.onRemove)}
//         />
//       </div>
//     );
//   };

//   const emptyTemplate = () => {
//     return (
//       <div className="flex align-items-center flex-column">
//         <i
//           className="pi pi-file"
//           style={{
//             fontSize: "5em",
//             borderRadius: "50%",
//           }}
//         ></i>
//         <span className="mt-4"
//         >
//           Drag and Drop File Here
//         </span>
//       </div>
//     );
//   };

//   const chooseOptions = {
//     icon: "pi pi-file",
//     iconOnly: true,
//     className: "custom-choose-btn p-button-rounded",
//   };
//   const cancelOptions = {
//      icon: 'pi pi-fw pi-times',
//      iconOnly: true,
//      className: 'p-button-danger p-button-rounded p-button-outlined cancel'
//   };


//   return (
//     <div className="flex justify-content-center mt-4">
//       <div
//         style={{
//           width: "95%",
//           background: "#fff",
//           borderRadius: "12px",
//           border: "1px solid #11409E",
//         }}
//       >
//         <div className="flex justify-content-center">
//           <div
//             className="flex align-items-center justify-content-center m-1"
//             style={{
//               width: "100%",
//               height: "4rem",
//               background: "#fff",
//             }}
//           >
//             <img
//               src="Vectorfile.png"
//               alt="logo"
//               className=" mr-2"
//               style={{ width: "38px" }}
//             />
//             <InputText
//               placeholder="Enter File Upload Question here..."
//               style={{ width: "90%", border: "none" }}
//             />
//           </div>
//         </div>

//         <div style={{ margin: "0px 4% 22px 4%" }}>
//           <Tooltip
//             target=".custom-choose-btn"
//             content="Choose"
//             position="bottom"
//           />

//           <FileUpload
//             ref={fileUploadRef}
//             name="demo[]"
//             url="/api/upload"
//             multiple
//             maxFileSize={5000000}
//             onSelect={onTemplateSelect}
//             onError={onTemplateClear}
//             onClear={onTemplateClear}
//             headerTemplate={headerTemplate}
//             itemTemplate={itemTemplate}
//             emptyTemplate={emptyTemplate}
//             chooseOptions={chooseOptions}
//             cancelOptions={cancelOptions}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUploads;


import React from "react";
import { InputText } from "primereact/inputtext";

const FileUploads = ({id, onInputChange, txtQuestion}) => {

  return (
    <div className="single-line flex justify-content-center">
      <div
        className="flex align-items-center justify-content-center mt-4"
        style={{
          width: "95%",
          height: "4rem",
          background: "#fff",
          borderRadius: "12px",
          border: "1px solid #11409E",
        }}
      >
        <img src="Vectorfile.png" alt="logo" className=" mr-2"  style={{ width: "38px" }}/>
        <InputText
          placeholder="Enter File Upload Question here..."
          style={{ width: "90%", border: "none" }}
          onChange={(e) => onInputChange(id, e.target.value)}
          value={txtQuestion}
        />
      </div>
    </div>
  );
};

export default FileUploads;
