import { InputText } from "primereact/inputtext";
import React from "react";

const Date = ({id,onInputChange,txtQuestion}) => {
  return (
    // <div className="flex justify-content-center mt-4">
    //   <div
    //     style={{
    //       width: "95%",
    //       background: "#fff",
    //       borderRadius: "12px",
    //       border: "1px solid #11409E",
    //     }}
    //   >
    //     <div className="flex justify-content-center">
    //       <div
    //         className="flex align-items-center justify-content-center m-1"
    //         style={{
    //           width: "100%",
    //           height: "4rem",
    //           background: "#fff",
    //         }}
    //       >
    //         <img
    //           src="Group 297.png"
    //           alt="logo"
    //           className=" mr-2"
    //           style={{ width: "32px" }}
    //         />
    //         <InputText
    //           placeholder="Enter Date Question here..."
    //           style={{ width: "90%", border: "none" }}
    //         />
    //       </div>
    //     </div>

    //     {/* <div style={{ marginLeft: "4%", marginRight:"10px" }}>
    //       <div
    //         style={{
    //           height: "4rem",
    //           background: "#fff",
    //         }}
    //       >
    //         <Calendar className="mr-4"
    //         placeholder="Date"
    //           onChange={(e) => setDate(e.value)}
    //           view="date"
    //           dateFormat="dd"
    //           style={{ width: "70px", height: "40px" }}
    //         />
    //         <Calendar className="mr-4"
    //         placeholder="Month"
    //           value={date}
    //           onChange={(e) => setDate(e.value)}
    //           view="month"
    //           dateFormat="mm"
    //           style={{   height: "40px" }}
    //         />
    //         <Calendar
    //         placeholder="Year"
    //           value={date}
    //           onChange={(e) => setDate(e.value)}
    //           view="year"
    //           dateFormat="yy"
    //           style={{ height: "40px" }}
    //         />
    //       </div>
    //     </div> */}
    //   </div>
    // </div>
    <div className="single-line flex justify-content-center">
    <div
      className="flex align-items-center justify-content-center mt-4"
      style={{
        width: "95%",
        height: "4rem",
        background: "#fff",
        borderRadius: "12px",
        border: "1px solid #11409E",
      }}
    >
      <img  src="Group 297.png" alt="logo" className=" mr-2" />
      <InputText
        placeholder="Enter Date Question here..."
        style={{ width: "90%", border: "none" }}
        onChange={(e) => onInputChange(id, e.target.value)}
        value={txtQuestion}
      />
    </div>
  </div>
  );
};

export default Date;
