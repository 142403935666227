import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../../api';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';

function Roles({ projectName, projectId }) {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [roles, setRoles] = useState([]);

    const getRoles = async () => {
      try {
        const requestData = {
          project_id: projectId,
          project_name : projectName,
          login_user_id : "admin",
          synceddatetime: "2023-08-25 14:51:50.853740",
          web_version: "1.0.1",
          db_version: "10.4.1",
          formcode: "207",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3"
        };

        const result = await api.get_Roles(requestData);
        setRoles(result.responsemessage);
        const rolesWithSlno = result.responsemessage.map((role, index) => ({ ...role, slno: index + 1 }));
        setRoles(rolesWithSlno);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

  useEffect(() => {
    getRoles();
  }, []);

  const roleType = [
    { name: "Data Entry", code: "2" },
    { name: "Only Reports", code: "3" },
    { name: "Both Data Entry and Only Reports", code: "4" },
  ];

  const [globalFilter, setGlobalFilter] = useState('');

  const onFilterInputChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const filteredProducts = roles.filter((role) =>
    Object.values(role).some((value) =>
      value.toString().toLowerCase().includes(globalFilter.toLowerCase())
    )
  );

  const deleteRole = async (rowData) => {
  
    try {
      const payload = {
        project_id: projectId,
        project_name: projectName,
        role_id: rowData.fld_role_id,
        role_type: rowData.fld_role_type,
        role_name: rowData.fld_role_name,
        user_id : "admin",
        synceddatetime: "2023-08-25 14:51:50.853740",
        web_version: "1.0.1",
        db_version: "10.4.1",
        formcode: "215",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3"
      };
  
      await api.deleteRole(payload);
  
      getRoles();
      toast.current.show({
        severity: "success",
        summary: "Role Deleted",
        detail: "Role deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error deleting role.",
      });
    }
  };

    const actionTemplate = (rowData) => {
        return (
            <div >
               {/* <i className="pi pi-pencil" /> */}
               <i className="pi pi-trash ml-5" style={{color:"red"}} onClick={() => deleteRole(rowData)} />
            </div>
        );
    };

  const renderHeader = () => {
    return (
        <div className="flex justify-content-between">
             <Button className='formbutton' icon="pi pi-plus-circle" onClick={() => setVisible(true)} ><span className='ml-2' style={{marginTop:"-2px"}}>Create Role</span></Button>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" placeholder="Global Search" value={globalFilter} onChange={onFilterInputChange} style={{height:"42px"}}/>
            </span>
        </div>
    );
};

const header = renderHeader();


const validationSchema = Yup.object({
  role_name: Yup.string().required('Role Name is required'),
  role_type: Yup.string().required('Select a Role'),
});

const formik = useFormik({
  initialValues: {
    role_name: '',
    role_type: '',
  },
  validationSchema,
  onSubmit: async (values, { resetForm }) => {
    try {
        const selectedRole = roleType.find(role => role.name === values.role_type);
        if (!selectedRole) {
            console.error("Selected role not found.");
            return;
        }
        const additionalData = {
            project_id: projectId,
            project_name: projectName,
            role_id: selectedRole.code,
            user_id: "admin",
            synceddatetime: "2023-08-25 14:51:50.853740",
            web_version: "1.0.1",
            db_version: "10.4.1",
            formcode: "204",
            appversion: "1.0.0",
            apikey: "kavin",
            apptypeno: "3"
        };

        const response = await api.create_Role(
            values.role_name,
            values.role_type,
            additionalData);

//         setVisible(false);
//         if (toast.current) {
//             toast.current.show({
//                 severity: "success",
//                 summary: "Role Created Successful",
//                 detail: "You are now Created Role",
//             });
//         }
//         resetForm();
//         getRoles();
//     } catch (error) {
//         console.error("Role form submission error:", error);
//         if (toast.current) {
//             toast.current.show({
//                 severity: "error",
//                 summary: "Role Create Failed",
//                 detail: "Role Name Already Existed",
//             });
//         }
//     }
// }

if (response && response.status === '1') {
  toast.current.show({
    severity: "success",
    summary: "Roles Created Successful",
    detail: response.responsemessage || "You have successfully created the Roles.",
  });
  setVisible(false);
    resetForm();
    getRoles();

} else {  
  throw new Error(response ? response.responsemessage || "An error occurred while creating the Roles." : "No response from the server.");
}
} catch (error) {
console.error("Roles submission error:", error);

toast.current.show({
  severity: "error",
  summary: " Roles Failed",
  detail: error.message || "An unexpected error occurred. Please try again.",
});
}
},


});


  return (
    <div>
      <Toast ref={toast} position="top-right" />
       <Dialog header="Create Role" visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)}>
       <form onSubmit={formik.handleSubmit}>
       <Divider className="mt-0" />
       <div style={{ marginTop: "30px" }}>
       <span className="flex justify-content-center align-items-center p-float-label">
            <InputText
              type="text"
              id="role_name"
              name="role_name"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role_name}
            />
            <label htmlFor="role_name">Role Name</label>
          </span>
          {formik.touched.role_name && formik.errors.role_name && (
  <div className="error-message" style={{ color: "red" }}>{formik.errors.role_name}</div>
)}
      </div>

      <div style={{ marginTop: "30px", padding: "0px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
      <Dropdown
        id="role_type"
        name="role_type"
        style={{ height: "45px", width: "100%" }}
        options={roleType}
        optionLabel="name"
        value={roleType.find(role => role.name === formik.values.role_type)}
        onChange={(e) => formik.setFieldValue('role_type', e.value.name)}
        onBlur={formik.handleBlur}
      />
            <label htmlFor="role_name">Select Role</label>
          </span>
          {formik.touched.role_type && formik.errors.role_type && (
           <div className="error-message" style={{ color: "red" }}>{formik.errors.role_type}</div>
          )}
        </div>
        <Divider className="mt-4" />
        <div style={{ marginTop: "20px"}} className="flex justify-content-end align-items-center">
        <Button style={{ color: "#11409E" }} label="Cancel" outlined onClick={() => setVisible(false)}/>
        <Button className="formbutton ml-3" type="submit" label="Create" />
        </div>
        </form>

            </Dialog>

<DataTable
        value={filteredProducts}
        tableStyle={{ minWidth: '50rem' }}
        header={header}
        columnResizeMode="expand"
        scrollHeight="350px"
        paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="slno" header="Sl. No" sortable/>  
        <Column field="fld_role_type" header="Role" sortable/>
        <Column field="fld_role_name" header="Role Name" sortable/>
        <Column header="Action" body={actionTemplate}/>
      </DataTable>
    </div>
  );
}

export default Roles;
