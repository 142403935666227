import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import api from "../api";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CreateProject from "./Create Project/CreateProject";
import { Divider } from "primereact/divider";

const Projects = ({ formName, questionsData }) => {

  const navigate = useNavigate();
  const toast = useRef(null);
  const [projectNames, setProjectNames] = useState([]);
  const [visibleProjects, setVisibleProjects] = useState({});
  const [showSecondDialog, setShowSecondDialog] = useState(false);
  const [deletionProject, setDeletionProject] = useState({ project_id: "", project_name: "" });
  const [visible, setVisible] = useState(false);
  const [editProject, setEditProject] = useState(null);
  
  const hideDialog = () => {
    setVisible(false);
    setEditProject(null);
  };
  const hideDialogAndShowToast = (responseMessage) => {
    hideDialog();
    toast.current.show({
      severity: "success",
      summary: "Project Created Successful",
      detail: responseMessage || "Operation successful.",
    });
  };

  useEffect(() => {
    const initialVisibility = {};
    projectNames.forEach((project) => {
      initialVisibility[project.fld_project_id] = false;
    });
    setVisibleProjects(initialVisibility);
  }, [projectNames]);

  const header = (
    <img alt="Card" src="Projectimg.png"/>
  );

  const deleteHeader = (
    <span className="flex align-items-center">
      <i
        className="pi pi-trash"
        style={{ fontSize: "1.5rem", marginRight: "10px" }}
      />
      Delete Confirmation
    </span>
  );

  const handleCardClick = (project) => {
    navigate(`/forms/${project.fld_project_id}/${project.fld_project_name}`);
  };

  const handlePencilIconClick = (event, project) => {
    event.stopPropagation();
    setVisible(true)
    setEditProject(project);
  };

  const handleDeleteClick = (event, project) => {
    event.stopPropagation();
    const updatedVisibility = { ...visibleProjects };
    updatedVisibility[project.fld_project_id] = true;
    setVisibleProjects(updatedVisibility);
  };

  const handleDeleteIconClick = (project_id, project_name, event) => {
    event.stopPropagation();
    setDeletionProject({ project_id, project_name });
    setShowSecondDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await api.delete_Project(
        deletionProject.project_id,
        deletionProject.project_name
      );
  
      if (response && response.status === '1') {
        setProjectNames((prevProjectNames) =>
          prevProjectNames.filter(
            (project) => project.fld_project_id !== deletionProject.project_id
          )
        );
        toast.current.show({
          severity: "success",
          summary: "Project Deleted Successfully",
          detail: response.responsemessage || "Project successfully removed.",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Failed to Delete Project",
          detail: response ? response.responsemessage || "An error occurred while deleting the project." : "No response",
        });
  
        console.error("Failed to delete project");
      }
    } catch (error) {
      console.error("Delete project error:", error.message);
      toast.current.show({
        severity: "error",
        summary: "Failed to Delete Project",
        detail: "An unexpected error occurred. Please try again.",
      });
    }
  };

    const [userRole, setUserRole] = useState("");
  const storedUserRole = sessionStorage.getItem("userRole");
  const fldNameOfTeamMember = sessionStorage.getItem('fldNameOfTeamMember');

  useEffect(() => {
    if (storedUserRole) {
      setUserRole(storedUserRole);
    } else {
      console.log("User role not found. Redirecting to login page.");

    }
  }, []);

  const getProjectNames = async () => {
    try {
      const jsonData = {
        user_name: "admin",
        synceddatetime: "2023-01-24 11:57:34",
        formcode: "206",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3",
        web_version: "1.0.1",
        db_version: "10.4.1",
      };
      const response = await api.getProject_Name(jsonData);
      console.log("GET request for project names was successful");

      if (response && response.data) {
        setProjectNames(response.data);
      } else {
        console.error("No data in response");
      }
    } catch (error) {
      console.error("GET request for project names failed:", error);
    }
  };

  const [latestData, setLatestData] = useState(null);
  const getUserData = async () => {
    try {
      const storedLoginResponse = sessionStorage.getItem("projectDetails");
      const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;

      if (loginResponse && loginResponse.fld_project_id && loginResponse.fld_project_name) {
        const jsonData = {
          project_id: loginResponse.fld_project_id,
          project_name: loginResponse.fld_project_name,
          requesttable: {
            master_tbl_dynamic_forms_new: "0",
            master_tbl_forms_details: "0",
            master_tbl_project_details: "0",
          },
          synceddatetime: "2023-11-15 14:48:08",
          formcode: "212",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3",
          web_version: "1.0.1",
          db_version: "10.4.1",
        };

        const response = await api.getUser_Data(jsonData);
        console.log("GET request for user data was successful");
        console.log(response);

        if (response && response.status === "1" && response.response && response.response.master_tbl_project_details) {
          setLatestData(response);
        } else {
          console.error("No data in response or invalid response structure");
        }
      } else {
        console.error("Login response is missing or incomplete.");
      }
    } catch (error) {
      console.error("GET request for user data failed:", error);
    }
  };

  useEffect(() => {
    const storedUserRole = sessionStorage.getItem("userRole");

    if (storedUserRole) {
      setUserRole(storedUserRole);

      if (storedUserRole === "1") {
        getProjectNames();
      } else {
        getUserData();
      }
    } else {
      console.log("User role not found. Redirecting to login page.");
    }
  }, []);

  const groupQuestionsByFormName = (questions) => {
    return questions.reduce((groupedQuestions, question) => {
      const formName = question.fld_form_name;
      if (!groupedQuestions[formName]) {
        groupedQuestions[formName] = [];
      }
      groupedQuestions[formName].push(question);
      return groupedQuestions;
    }, {});
  };

  const handleUserCardClick1 = (formName) => {
    navigate(`/form/${formName}?questions=${encodeURIComponent(
      JSON.stringify(
        groupQuestionsByFormName(
          latestData.response.master_tbl_dynamic_forms_new
        )[formName]
      )
    )}`);
  };

  const handleEyeIconClick = (event, formName) => {
    event.stopPropagation();
    navigate(`/form/${formName}?questions=${encodeURIComponent(
      JSON.stringify(
        groupQuestionsByFormName(
          latestData.response.master_tbl_dynamic_forms_new
        )[formName]
      )
    )}`);
    console.log("object")
  };
  

  return (
    <div style={{ margin: "20px" }}>
      <Header fldNameOfTeamMember={fldNameOfTeamMember}/>
      <Toast ref={toast} position="top-right" />
           {userRole === "1" && (
      <div style={{ margin: "1pc" }}>
          <h1 style={{marginTop:"-5px"}}>Projects</h1>

        <div
          className="grid"
          style={{
            display: "flex",
            gap: "21px",
            marginTop: "20px",
          }}
        >
          <div
            className="col-2 my-card center"
            style={{
              border: "solid 1px #E0E1E2",
              borderRadius: "10px",
              minHeight: "292px",
            }}
            onClick={() => setVisible(true)}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i className="pi pi-plus"></i>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "20px" }}>Create Project</p>
              </div>
            </div>
          </div>

          {projectNames.map((project, index) => (
            <>
              <Card
                key={project.fld_project_id}
                title={project.fld_project_name}
                subTitle={project.fld_project_description}
                className="col-2 my-card"
                header={header}
                onClick={() => handleCardClick(project)}
                >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                  <i className="pi pi-pencil" onClick={(event) => handlePencilIconClick(event, project)}></i>
                    <i
                      className="pi pi-trash ml-3"
                      onClick={(event) => handleDeleteClick(event, project)}
                    ></i>
                  </div>
                  <span style={{ fontSize: "10px" }}>04/09/2023</span>

                </div>
              </Card>
              <Dialog
                header={deleteHeader}
                visible={visibleProjects[project.fld_project_id]}
                style={{width:"30rem"}}
                onHide={() =>
                  setVisibleProjects((prevState) => ({
                    ...prevState,
                    [project.fld_project_id]: false,
                  }))
                }
              >
                <span>Are you sure you want to delete this project?</span>
                <div className="flex justify-content-end mt-4">
                  <Button
                    style={{ color: "#11409E" }}
                    label="No"
                    outlined
                    onClick={() =>
                      setVisibleProjects((prevState) => ({
                        ...prevState,
                        [project.fld_project_id]: false,
                      }))
                    }
                  />

                  <Button
                    label="Yes"
                    className="ml-2"
                    severity="danger"
                    onClick={(event) => {
                      handleDeleteIconClick(
                        project.fld_project_id,
                        project.fld_project_name,
                        event
                      );
                      setVisibleProjects((prevState) => ({
                        ...prevState,
                        [project.fld_project_id]: false,
                      }));
                    }}
                  />
                </div>
              </Dialog>
            </>
          ))}
          <Dialog
            header={deleteHeader}
            visible={showSecondDialog}
            onHide={() => setShowSecondDialog(false)}
            style={{width:"30rem"}}
          >
            <p>All the data related to this project will be deleted</p>
            <p>Are you sure to delete this project</p>
            <div className="flex justify-content-end mt-4">
              <Button
                style={{ color: "#11409E" }}
                label="No"
                outlined
                onClick={() => setShowSecondDialog(false)}
              />
              <Button
                label="Yes"
                className="ml-2"
                severity="danger"
                onClick={() => {
                  handleConfirmDelete();
                  setShowSecondDialog(false);
                }}
              />
            </div>
          </Dialog>
        </div>
        <Dialog header={` ${editProject ? editProject.fld_project_name : 'Create Project'}`} visible={visible} onHide={hideDialog}>
           <Divider className="mt-0" />
           <CreateProject hideDialog={hideDialogAndShowToast} hideDialogCancel={hideDialog} editProject={editProject} onProjectCreated={getProjectNames}/>
        </Dialog>
      </div>
      )}
      {userRole === "2" && (
        <div style={{ margin: "1pc" }}>

{latestData && latestData.response && latestData.response.master_tbl_dynamic_forms_new && (
    <div>
      <h1 style={{marginTop:"-5px"}}>{latestData.response.master_tbl_project_details[0].fld_project_name}</h1>
      <div className="grid" style={{ display: "flex", gap: "21px", marginTop: "20px" }}>

      {Object.keys(
        groupQuestionsByFormName(
          latestData.response.master_tbl_dynamic_forms_new
        )
      ).map((formName) => (
          <Card
            title={formName}
            className="col-2 my-card"
            header={header}
            onClick={() => handleUserCardClick1(formName)}
          >

            <div className="flex justify-content-between">
              <Button style={{padding:"5px 10px", height:"40px", fontSize:"14px", fontWeight:"600" }} severity="success" >Fill Form</Button>
              <Button style={{padding:"5px 10px", height:"40px", fontSize:"14px", fontWeight:"600", background:"#11409E"}} icon="pi pi-download"><span className="ml-2">Reports</span></Button>
            </div>
          </Card>
      ))}
    </div>
    </div>
  )}

        </div>
      )}

      {userRole === "3" && (
        <div>
          <p>Welcome Only Reports User! You can see user-specific content here.</p>
          {latestData && latestData.response && latestData.response.master_tbl_project_details && (
        <div>
          <h2>Project Details</h2>
          <p>Project ID: {latestData.response.master_tbl_project_details[0].fld_project_id}</p>
          <p>Project Name: {latestData.response.master_tbl_project_details[0].fld_project_name}</p>
        </div>
      )}
        </div>
      )}
            {userRole === "4" && (
        <div>
          <p>Welcome Both Data entry and Reports User! You can see user-specific content here.</p>
          {latestData && latestData.response && latestData.response.master_tbl_project_details && (
        <div>
          <h2>Project Details</h2>
          <p>Project ID: {latestData.response.master_tbl_project_details[0].fld_project_id}</p>
          <p>Project Name: {latestData.response.master_tbl_project_details[0].fld_project_name}</p>

        </div>
      )}
        </div>
      )}

      {userRole !== "1" && userRole !== "2" && userRole !== "3" && userRole !== "4" && (
        <div>
          <p>Unknown user role. Please log in with a valid account.</p>
        </div>
      )}
    </div>
  );
};

export default Projects;



// import React, { useState, useEffect } from "react";
// import api from "../api";

// const Projects = () => {
//   const [latestData, setLatestData] = useState(null);

//   const getUser = async () => {
//     try {
//       const storedLoginResponse = sessionStorage.getItem('projectDetails');
//       const loginResponse = storedLoginResponse ? JSON.parse(storedLoginResponse) : null;

//       if (loginResponse && loginResponse.fld_project_id && loginResponse.fld_project_name) {
//         const jsonData = {
//           project_id: loginResponse.fld_project_id,
//           project_name: loginResponse.fld_project_name,
//           requesttable: {
//             master_tbl_dynamic_forms_new: "0",
//             master_tbl_forms_details: "0",
//             master_tbl_project_details: "0"
//           },
//           synceddatetime: "2023-11-15 14:48:08",
//           formcode: "212",
//           appversion: "1.0.0",
//           apikey: "kavin",
//           apptypeno: "3",
//           web_version: "1.0.1",
//           db_version: "10.4.1"
//         };

//         const response = await api.getUser_Data(jsonData);
//         console.log("Full API response:", response);
//         console.log("GET request was successful");

//         if (response && response.status === '1' && response.response && response.response.master_tbl_project_details) {
//           setLatestData(response);
//           console.log("Data received successfully:", response.response);
//         } else {
//           console.error("No data in response or invalid response structure");
//         }
//       } else {
//         console.error("Login response is missing or incomplete.");
//       }
//     } catch (error) {
//       console.error("GET request failed:", error);
//     }
//   };

//   useEffect(() => {
//     getUser();
//   }, []);

//   return (
//     <div>
//       <h1>Projects Page</h1>
//       <div>
//         <p>Welcome Admin! You can see admin-specific content here.</p>
//       </div>
//       {latestData && latestData.response && latestData.response.master_tbl_project_details && (
//         <div>
//           <h2>Project Details</h2>
//           <p>Project ID: {latestData.response.master_tbl_project_details[0].fld_project_id}</p>
//           <p>Project Name: {latestData.response.master_tbl_project_details[0].fld_project_name}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Projects;
