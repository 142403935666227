import React, { useState } from "react";
import { InputText } from "primereact/inputtext";

const MultiLine = ({id, onInputChange, txtQuestion }) => {

  return (
    <div className="single-line flex justify-content-center">
      <div
        className="flex align-items-center justify-content-center mt-4"
        style={{
          width: "95%",
          height: "4rem",
          background: "#fff",
          borderRadius: "12px",
          border: "1px solid #11409E",
        }}
      >
        <img src="Group 237719.png" alt="logo" className="mr-2" />
        <InputText
          autoResize
          placeholder="Enter Multi Line Question here..."
          style={{ width: "90%", border: "none" }}
          onChange={(e) => onInputChange(id, e.target.value)}
          value={txtQuestion } 
       />
      </div>
    </div>
  );
};

export default MultiLine;
