import { Button } from 'primereact/button'
import { TieredMenu } from 'primereact/tieredmenu'
import React from 'react'
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';

const Header = ({ fldNameOfTeamMember }) => {
    const menu1 = useRef(null);
    const navigate = useNavigate();
    const clearSession = () => {
      sessionStorage.removeItem('sessionId');
      sessionStorage.removeItem('userRole');
      sessionStorage.removeItem('fldNameOfTeamMember');
      sessionStorage.removeItem('projectDetails');

      navigate('/');
    };
    const items2 = [
        {
          label: "Log out",
          icon: "pi pi-fw pi-power-off",
          command: () => {
            clearSession();
          },
        },
      ];
  return (
    <>
   <div style={{display:"flex", justifyContent:"space-between"}}>
    <img src={process.env.PUBLIC_URL + "/FormMain.png"}alt="logo" style={{width:"112px", height:"37px"}}/>
    <span style={{display:"flex", alignItems:"center"}}>
    {fldNameOfTeamMember ? fldNameOfTeamMember : "Admin"}
    <TieredMenu model={items2} popup ref={menu1} />
    <Button
      icon="pi pi-angle-down"
      className="custom-button"
      style={{
        background: "rgb(255 255 255)",
        borderColor: "rgb(255 255 255)",
        height: "40px",
        width: "40px",
        color: "black",
      }}
      onClick={(e) => menu1.current.toggle(e)}
    />
    </span>
    </div>
    <Divider />

    </>
  )
}

export default Header