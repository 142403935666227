import React from "react";
import { InputText } from "primereact/inputtext";

const Label = ({id,onInputChange,txtQuestion}) => {
  return (
    <div className="flex justify-content-center">
      <div
        className="flex align-items-center justify-content-center mt-4"
        style={{
          width: "95%",
          height: "4rem",
          background: "#fff",
          borderRadius: "12px",
          border: "1px solid #11409E",
        }}
      >
        <img src="Group 278.png" alt="logo" className=" mr-2" style={{width:"32px"}}/>
        <InputText
          placeholder="Enter Label Question here..."
          style={{ width: "90%", border: "none" }}
          onChange={(e) => onInputChange(id, e.target.value)}
          value={txtQuestion}
        />
      </div>
    </div>
  );
};

export default Label;
