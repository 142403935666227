import React from 'react'
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";

const LabelRightBarContent = ({
    id,
    inputSwitchState,
    dropdownState,
    handleInputChange,
    handleDropdownChange,
    handleDelete,
    handleDuplicate
}) => {
    const size = [
        { name: "Heading 1", code: "H1" },
        { name: "Heading 2", code: "H2" },
        { name: "Heading 3", code: "H3" },
        { name: "Heading 4", code: "H4" },
        { name: "Heading 5", code: "H5" },
        { name: "Heading 6", code: "H6" },
      ];
      return (
        <div>
          <TabView>
            <TabPanel header="Validation">
              <div>
              <div className="flex justify-content-between align-items-center">
                Mandatory{" "}
                <InputSwitch
                  checked={inputSwitchState}
                  onChange={(e) => handleInputChange(id, e)}
                />
              </div>


              <div className="flex justify-content-between align-items-center mt-3">
              Label Size{" "}
                <Dropdown
                  style={{ height: "40px", width: "60%" }}
                  value={dropdownState}
                  onChange={(e) => handleDropdownChange(id, e)}
                  options={size}
                  optionLabel="name"
                  placeholder="Select type"
                />
              </div>

           <div className="flex justify-content-around mt-5">
           <Button className="p-2" severity="success" onClick={() => handleDuplicate(id)}>Duplicate Question</Button>
           <Button className="p-2 ml-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>
           </div>
           </div>
            </TabPanel>

            <TabPanel header="Skip Logic">
              <p className="m-0">Skip Logic</p>
            </TabPanel>
          </TabView>
          <div style={{position:"absolute", bottom:"0", padding:"1rem 1rem 0rem 1rem", fontSize:"14px"}}>
              <b> More Information </b>
               <p>Label is used for creating headings for a particular section of questions. You can choose multiple font weights for your heading.</p>
          </div>
     </div>
   )
}

export default LabelRightBarContent