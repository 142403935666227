import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import { v4 as uuidv4 } from 'uuid';


const Login = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (values) => {
      try {
        const additionalData = {
          role_id: "",
          synceddatetime: "2023-01-24 11:57:34",
          formcode: "108",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3",
        };
    
        const response = await api.login(values.username, values.password, additionalData);
        const sessionId = uuidv4();
        sessionStorage.setItem('sessionId', sessionId);
    
        if (response && response.status === '1') {
          if (response.loged_in_details && response.loged_in_details.length > 0) {
            const userRole = response.loged_in_details[0].fld_user_role;
    
            if (userRole === 1) {
              sessionStorage.setItem('userRole', response.loged_in_details[0].fld_user_role);
              sessionStorage.setItem('fldNameOfTeamMember', response.loged_in_details[0].fld_username);

              navigate("/projects");
              toast.current.show({
                severity: "success",
                summary: "Login Successful",
                detail: "You are now logged in as Admin!",
              });
            } else if (userRole === 2) {
              sessionStorage.setItem('userRole', response.loged_in_details[0].fld_user_role);
              sessionStorage.setItem('fldNameOfTeamMember', response.loged_in_details[0].fld_username);
              sessionStorage.setItem('projectDetails', JSON.stringify(response.loged_in_details[0]));

              navigate("/projects");
              toast.current.show({
                severity: "success",
                summary: "Login Successful",
                detail: "You are now logged in with a different role!",
              });
            } else if (userRole === 3) {
              sessionStorage.setItem('userRole', response.loged_in_details[0].fld_user_role);
              sessionStorage.setItem('fldNameOfTeamMember', response.loged_in_details[0].fld_username);
              sessionStorage.setItem('projectDetails', JSON.stringify(response.loged_in_details[0]));

              navigate("/projects");
              toast.current.show({
                severity: "success",
                summary: "Login Successful",
                detail: "You are now logged in with a different role!",
              });
            } else if (userRole === 4) {
              sessionStorage.setItem('userRole', response.loged_in_details[0].fld_user_role);
              sessionStorage.setItem('fldNameOfTeamMember', response.loged_in_details[0].fld_username);
              sessionStorage.setItem('projectDetails', JSON.stringify(response.loged_in_details[0]));


              navigate("/projects");
              toast.current.show({
                severity: "success",
                summary: "Login Successful",
                detail: "You are now logged in with a different role!",
              });
            }
             else {
              console.log('Unknown user role:', userRole);
            }
          } else {
            console.log('Invalid API response format:', response);
            toast.current.show({
              severity: "error",
              summary: "Login Failed",
              detail: "Unexpected API response format",
            });
          }
        } else {
          console.log('API response:', response);
    
          const errorMessage = response ? response.responsemessage || "Invalid username or password" : "Unexpected API response format";
          toast.current.show({
            severity: "error",
            summary: "Login Failed",
            detail: errorMessage,
          });
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "An unexpected error occurred. Please try again.",
        });
      }
    },
    

  });



  return (
    <div className="body">
      <div className="grid">
        <div className="col">
          <img src="Frame55121.jpg" alt="background" className="image"/>
          <div className="text-overlay">
            <img src="Group.png" alt="logo"/>

          </div>
        </div>
        <div className="col">

            <form onSubmit={formik.handleSubmit} style={{width:"45%"}}>
              <div>
              <h2 className="flex justify-content-center">Welcome Login </h2>
                <div className="mt-5">
                  <span className="p-float-label">
                    <InputText
                    style={{width:"100%"}}
                      id="username"
                      name="username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                      className={
                        formik.errors.username && formik.touched.username
                          ? "p-invalid p-inputtext"
                          : "p-inputtext"
                      }
                    />
                    <label htmlFor="username">Username</label>
                  </span>

                  {formik.errors.username && formik.touched.username && (
                    <span className="p-error">{formik.errors.username}</span>
                  )}
                </div>
                <br></br>

                <div className="mt-3">
                  <span className="p-float-label">
                    <Password
                    style={{width:"100%"}}
                      name="password"
                      toggleMask
                      feedback={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className={
                        formik.errors.password && formik.touched.password
                        ? "p-invalid p-password"
                        : "p-password"
                      }
                    />
                    <label htmlFor="password"> Password</label>
                  </span>

                  {formik.errors.password && formik.touched.password && (
                    <span className="p-error">{formik.errors.password}</span>
                  )}
                </div>

                <br></br>
              </div>
              <Toast ref={toast} position="top-right" />
              <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
               <Link>Forgot Password ?</Link>
                <Button className="formbutton" type="submit" label="Login" />
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
