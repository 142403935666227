// import { InputText } from 'primereact/inputtext'
// import { RadioButton } from 'primereact/radiobutton';
// import React, { useState } from 'react'

// const YesNo = ({ id, onInputChange }) => {
//     const categories = [
//         { name: 'Yes', key: 'Y' },
//         { name: 'No', key: 'N' },
//     ];
//     const [selectedCategory, setSelectedCategory] = useState(categories);

//   return (
//     <div  className="flex justify-content-center mt-4">
//     <div
//      style={{
//         width: "95%",
//         background: "#fff",
//         borderRadius: "12px",
//         border: "1px solid #11409E",
//       }}>
//     <div className="flex justify-content-center">
//     <div
//       className="flex align-items-center justify-content-center m-1"
//       style={{
//         width: "100%",
//         height: "4rem",
//         background: "#fff",
//       }}
//     >
//       <img src="Group 295.png" alt="logo" className="mr-2" style={{width:"32px"}}/>
//       <InputText
//               placeholder="Enter Checkbox Question here..."
//               style={{ width: "90%", border: "none" }}
//               onChange={(e) => onInputChange(id, e.target.value)}
//             />
//     </div>
//    </div>


//     <div style={{marginLeft:"4%", marginBottom:"10px"}}>
//     {categories.map((category) => {
//                     return (
//                         <div key={category.key} className="flex align-items-center mb-3">
//                             <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key} disabled/>
//                             <label htmlFor={category.key} className="ml-2">{category.name}</label>
//                         </div>
//                     );
//                 })}
//     </div>
//    </div>
//    </div>
//   )
// }

// export default YesNo


import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useState } from 'react';

const YesNo = ({ id, onInputChange, txtQuestion }) => {
  const categories = [
    { name: 'Yes', key: 'Y' },
    { name: 'No', key: 'N' },
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  return (
    <div className="flex justify-content-center mt-4">
      <div
        style={{
          width: '95%',
          background: '#fff',
          borderRadius: '12px',
          border: '1px solid #11409E',
        }}
      >
        <div className="flex justify-content-center">
          <div
            className="flex align-items-center justify-content-center m-1"
            style={{
              width: '100%',
              height: '4rem',
              background: '#fff',
            }}
          >
            <img
              src="Group 295.png"
              alt="logo"
              className="mr-2"
              style={{ width: '32px' }}
            />
            <InputText
              placeholder="Enter Yes/No Question here..."
              style={{ width: '90%', border: 'none' }}
              onChange={(e) => onInputChange(id, e.target.value)}
              value={txtQuestion}
            />
          </div>
        </div>

        <div style={{ marginLeft: '4%', marginBottom: '10px' }}>
          {categories.map((category) => {
            return (
              <div key={category.key} className="flex align-items-center mb-3">
                <RadioButton
                  inputId={category.key}
                  name="category"
                  value={category}
                  onChange={(e) => setSelectedCategory(e.value)}
                  // checked={selectedCategory.key === category.key}
                  disabled
                />
                <label htmlFor={category.key} className="ml-2">
                  {category.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default YesNo;