import React from 'react';

const NotFound = () => {
  return (
    <div>
      page not found
    </div>
  );
}

export default NotFound;
