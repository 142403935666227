import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import * as Yup from "yup";
import api from "../../api";
import { Toast } from "primereact/toast";
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from "primereact/progressbar";
import { Tag } from 'primereact/tag';
import { SelectButton } from 'primereact/selectbutton';
import { Divider } from "primereact/divider";
import { Tooltip } from 'primereact/tooltip';



const CreateProject = ({ hideDialog, editProject, onProjectCreated, hideDialogCancel }) => {
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const options = ['Form', 'Quiz'];

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
        _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
    console.log("selected file", files)
};

const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
};

const onTemplateClear = () => {
    setTotalSize(0);
};

const headerTemplate = (options) => {
    const { className, chooseButton} = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
            <div className="flex align-items-center gap-3 ml-auto">
                <span>{formatedValue} / 1 MB</span>
                <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div>
        </div>
    );
};

const itemTemplate = (file, props) => {
    return (
        <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center" style={{ width: '40%' }}>
                <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                <span className="flex flex-column text-left ml-3">
                    {file.name}
                    <small>{new Date().toLocaleDateString()}</small>
                </span>
            </div>
            <Tag value={props.formatSize} severity="warning" className="px-3 py-2 ml-6" />
            <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
    );
};

const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image" style={{ fontSize: '2em', borderRadius: '50%', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1rem', color: 'var(--text-color-secondary)' }}>
                Drag and Drop Image Here
            </span>
        </div>
    );
};

  const validationSchema = Yup.object({
    project_name: Yup.string().required("Project Name is required"),
    project_description: Yup.string().required("Project Description is required"),
    // profile_pic_name: Yup.array().required('filesRequired', 'At least one file is required', (value) => {
    //   return value && value.length > 0;
    // }),
    form_display_type_name: Yup.string().required('Select the form type'),
  });
  const formik = useFormik({
    initialValues: {
      project_name: editProject ? editProject.fld_project_name : "",
      project_description: editProject ? editProject.fld_project_description:"",
      // profile_pic_name: null,
      form_display_type_name: "",
      form_display_type_id: "",
    },
    validationSchema,
    // onSubmit: async (values) => {
    //   try {
    //     const additionalData = {
    //       login_id: "1",
    //       login_user: "admin",
    //       form_data_entered: "1",
    //       synceddatetime: "2023-08-25 14:51:50.853740",
    //       web_version: "1.0.1",
    //       db_version: "10.4.1",
    //       formcode: "202",
    //       appversion: "1.0.0",
    //       apikey: "kavin",
    //       apptypeno: "3"
    //     };

    //     await api.create_Project(
    //         values.project_name,
    //         values.project_description,
    //         values.form_display_type_name,
    //         values.form_display_type_id,
    //         additionalData
    //       );
    //     hideDialog();
    //     toast.current.show({
    //       severity: "success",
    //       summary: "Project Created Successful",
    //       detail: "You are now Created Project",
    //     });
    //     onProjectCreated();
    //   } catch (error) {
    //     console.error("Login form submission error:", error);

    //     toast.current.show({
    //       severity: "error",
    //       summary: "Project Create Failed",
    //       detail: "Project Name Already Existed",
    //     });
    //   }
    // },
    onSubmit: async (values) => {
      try {
        const additionalData = {
          login_id: "1",
          login_user: "admin",
          form_data_entered: "1",
          synceddatetime: "2023-08-25 14:51:50.853740",
          web_version: "1.0.1",
          db_version: "10.4.1",
          formcode: "202",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3"
        };
    
        const response = await api.create_Project(
          values.project_name,
          values.project_description,
          values.form_display_type_name,
          values.form_display_type_id,
          additionalData
        );
    
        if (response && response.status === '1') {
          toast.current.show({
            severity: "success",
            summary: "Project Created Successful",
            detail: response.responsemessage || "You have successfully created the project.",
          });
          hideDialog(response.responsemessage);

          onProjectCreated();
        } else {
          throw new Error(response ? response.responsemessage || "An error occurred while creating the project." : "No response from the server.");
        }
      } catch (error) {
        console.error("Create project submission error:", error);
    
        toast.current.show({
          severity: "error",
          summary: "Project Create Failed",
          detail: error.message || "An unexpected error occurred. Please try again.",
        });
      }
    },
        
    
    
 
  });
    const handleFormTypeChange = (e) => {
    const selectedFormType = e.value;
    let formTypeId;

    if (selectedFormType === 'Form') {
      formTypeId = '1';
    } else if (selectedFormType === 'Quiz') {
      formTypeId = '2';
    } else {
      formTypeId = '';
    }
    formik.setFieldValue('form_display_type_name', selectedFormType);
    formik.setFieldValue('form_display_type_id', formTypeId);
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>

        <div style={{ marginTop: "30px", padding: "0px" }}>
          <span className="flex justify-content-center align-items-center p-float-label">
            <InputText
              type="text"
              id="project_name"
              name="project_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.project_name}
              style={{ width: "100%" }}
              disabled={editProject ? true : false}
            />
            <label htmlFor="project_name">Project Name</label>
          </span>
          {formik.touched.project_name && formik.errors.project_name ? (
            <div className="flex justify-content-start" style={{ color: "red" }}>
              {formik.errors.project_name}
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px", padding: "0px" }}>
          <span className="flex justify-content-center align-items-center p-float-label">
            <InputTextarea
              type="text"
              id="project_description"
              name="project_description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.project_description}
              style={{ width: "100%", minWidth:"100%" }}
            />
            <label htmlFor="project_description">Project Description</label>
          </span>
          {formik.touched.project_description && formik.errors.project_description ? (
            <div className="flex justify-content-start" style={{ color: "red" }}>
              {formik.errors.project_description}
            </div>
          ) : null}
        </div>

        <div className="flex align-items-center" style={{ marginTop: "30px", padding: "0px" }}>
        <label className="mr-3">Choose Form Type :</label>
          <SelectButton
            name="form_display_type_name"
            id="form_display_type_name"
            value={formik.values.form_display_type_name}
            onChange={handleFormTypeChange}
            onBlur={formik.handleBlur}
            options={options}
            className={formik.touched.form_display_type_id && formik.errors.form_display_type_name ? 'p-invalid' : ''}
          />
         <Tooltip target=".custom-target-icon" />
         <i className="custom-target-icon pi pi-info-circle p-text-secondary ml-5"
                data-pr-tooltip="No notifications"
                data-pr-position="top"
                style={{ fontSize: '1.5rem', cursor: 'pointer' }}>
            </i>
      </div>
      {formik.touched.form_display_type_name && formik.errors.form_display_type_name && (
          <div className="p-error">{formik.errors.form_display_type_name}</div>
        )}

        {/* <div style={{ marginTop: "30px", padding: "0px" }}>
            <FileUpload chooseLabel="Browse"
            ref={fileUploadRef}
              name="profile_pic_name"
              url="/api/upload"
              multiple
              accept="image/*"
              maxFileSize={1000000}
              // onUpload={(event) => {
              //   formik.setFieldValue('profile_pic_name', event.profile_pic_name);
              // }}
              onSelect={onTemplateSelect}
              onError={onTemplateClear}
              onClear={onTemplateClear}
              headerTemplate={headerTemplate}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
            />
           {formik.touched.profile_pic_name && formik.errors.profile_pic_name && (
            <div>{formik.errors.profile_pic_name}</div>
          )}
       </div> */}
<Divider/>

        <Toast ref={toast} position="top-right" />
        <div className="flex justify-content-end align-items-center">
        <Button style={{ color: "#11409E" }} label="Cancel" outlined onClick={() => hideDialogCancel()}/>
        <Button className="formbutton ml-3" type="submit" label="Submit"/>
        </div>
      </form>
    </div>
  );
};

export default CreateProject;
