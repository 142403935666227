import axios from "axios";

// const BASE_URL = 'http://10.60.62.163:8000/react_webservices';
// const BASE_URL = 'http://10.60.60.103:8000/react_webservices';
// const BASE_URL = 'http://10.60.60.95:8000//react_webservices';


const BASE_URL = 'https://northstartest.kavinsoft.in/react_webservices';

const api = {
  // login: async (username, password, additionalData) => {
  //   try {
  //     const response = await axios.post(`${BASE_URL}/web_login`, {
  //       username,
  //       password,
  //       ...additionalData
  //     }, {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'application/json'
  //       }
  //     });

  //     if (response.data.status === '1') {
  //       console.log('Login successful');
  //     } else {
  //       console.error('Login failed:', response.data.response);
  //       throw new Error('Login failed');
  //     }
  //   } catch (error) {
  //     console.error('Login error:', error.message);
  //     throw error;
  //   }
  // },

  // login: async (username, password, additionalData) => {
  //   try {
  //     const response = await axios.post(`${BASE_URL}/web_login`, {
  //       username,
  //       password,
  //       ...additionalData
  //     }, {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'application/json'
  //       }
  //     });
  
  //     return response.data;
  //   } catch (error) {
  //     console.error('Login error:', error.message);
  //     throw error;
  //   }
  // },

    login: async (username, password, additionalData) => {
      try {
        const response = await axios.post(`${BASE_URL}/web_login`, {
          username,
          password,
          ...additionalData
        },
        {
          headers:{
            "Content-Type":"application/json"
          }
        });
    
        return response.data;
      } catch (error) {
        console.error('Login error:', error.message);
        throw error;
      }
    },
  


  create_Project: async (project_name, project_description, form_display_type_name, form_display_type_id, additionalData) => {
    try {
      const response = await axios.post(`${BASE_URL}/web_create_project`, {
        project_name,
        project_description,
        form_display_type_name,
        form_display_type_id,
        ...additionalData
      }, 
      {
        headers: {
         'Content-Type': 'application/json'
        }
      }
      );
  
      console.log('Response:', response);

    return response.data;
  } catch (error) {
    console.error('Project Created error', error.message);
    throw error;
  }
  },


   create_Forms : async (staticData, dynamicData) => {
    try {
      const dynamicDataCount = Object.keys(dynamicData).length;
  
      const payload = {
        ...staticData,
        ...dynamicData,
        newKey: dynamicDataCount,
      };
  
      const response = await axios.post(`${BASE_URL}/form_question_insert_ajax`, payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
    if (response.data && response.data.status === '1') {
      console.log('Form Created successfully');
    } else {
      console.error('Form Created failed:', response.data || 'No response data available');
      throw new Error('Form Created failed');
    }
  } catch (error) {
    console.error('Form Created error:', error.message);
    throw error;
  }
  },


  getProject_Name: async (jsonData) => {
    try {
      const response = await axios.post(`${BASE_URL}/web_get_latest_pro_data`, jsonData, 
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching project data:', error.message);
      throw error;
    }
  },


  delete_Project: async (project_id, project_name) => {
    try {
      const response = await axios.post(`${BASE_URL}/web_delete_project`,
        {
          project_id,
          project_name,
          synceddatetime: "2023-01-24 11:57:34",
          reason_for_deletion:"i don't need this project",
          formcode: "108",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3",
          web_version: "'1.0.1'",
          db_version: "10.4.1"
        },
        {
          headers: {
           'Content-Type': 'application/json'
          }
        }
      );
    return response.data;
  } catch (error) {
    console.error('Delete Project error:', error.message);
    throw error;
  }
  },


create_Role: async (role_name, role_type, additionalData) => {
  try {
    const response = await axios.post(`${BASE_URL}/web_create_roles`, {
      role_name,
      role_type,
      ...additionalData
    }, 
    {
      headers: {
      'Content-Type': 'application/json'
      }
    }
    );

    console.log('Response:', response);
  return response.data;
} catch (error) {
  console.error('Team Member Created error', error.message);
  throw error;
}
},


get_Roles: async (requestData) => {
  try {
    const response = await axios.post(`${BASE_URL}/get_latest_roles`, requestData, 
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );
      return response.data;
  } catch (error) {
    console.error('Error fetching role data:', error.message);
    throw error;
  }
},

deleteRole: async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/web_delete_roles`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Delete role error:', error.message);
    throw error;
  }
},

create_TeamMember : async ( name_of_team_member,mobile_number,
  email_id,
  select_gender,
  role_name,
  new_user_name,
  new_user_password,
  additionalData) => {
  try {
    const response = await axios.post(`${BASE_URL}/web_create_team_member`, {
      name_of_team_member,
      mobile_number,
      email_id,
      select_gender,
      role_name,
      new_user_name,
      new_user_password,
      ...additionalData
    }, 
    {
      headers: {
      'Content-Type': 'application/json'
      }
    }
    );

    console.log('Response:', response);
  return response.data;
} catch (error) {
  console.error('Team Member Created error', error.message);
  throw error;
}
},

get_TeamMembers : async (requestData) => {
  try {
    const response = await axios.post(`${BASE_URL}/get_latest_team_members`, requestData,
     {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );
      return response.data;
  } catch (error) {
    console.error('Error fetching Team Members data:', error.message);
    throw error;
  }
},

deleteTeamMembers: async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/web_delete_team_member`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Delete role error:', error.message);
    throw error;
  }
},

get_FormDetails : async (requestData) => {
  try {
    const response = await axios.post(`${BASE_URL}/get_forms_details`, requestData, 
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );
      return response.data;
  } catch (error) {
    console.error('Error fetching Team Members data:', error.message);
    throw error;
  }
},

deleteForms: async (payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/web_delete_form`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Delete role error:', error.message);
    throw error;
  }
},

publishForm: async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/forms_acitve_inactive`, formData, 
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    );

    return response.data;
  } catch (error) {
    console.error('Publish form error:', error);
    throw error;
  }
},

getUser_Data: async (jsonData) => {
  try {
    const response = await axios.post(`${BASE_URL}/GetLatest`, jsonData, 
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching project data:', error.message);
    throw error;
  }
},

syncData: async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/SyncData`, payload, 
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );

    return response.data;
  } catch (error) {
    console.error('Sync Data error:', error.message);
    throw error;
  }
},

};

export default api;
