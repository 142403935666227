// import React, { useState } from "react";
// import { InputSwitch } from "primereact/inputswitch";
// import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
// import { TabPanel, TabView } from "primereact/tabview";
// import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";

// const DateRightBarContent = ({
//   id,
//   inputSwitchState,
//   inputSwitchState1,
//   inputSwitchState2,
//   dropdownState,
//   dropdownState1,
//   dropdownState2,
//   valueState,
//   textareaState,
//   handleInputChange,
//   handleInputChange1,
//   handleInputChange2,
//   handleDropdownChange,
//   handleDropdownChange1,
//   handleDropdownChange2,
//   handleValueChange,
//   handleTextareaChange,
//   handleDelete,
//   date, date1,setContextDate,setContextDate1
// }) => {


//   const types = [
//     { name: "Full date", code: "NY" },
//     { name: "Month Only", code: "MM" },
//     { name: "Year only", code: "LDN" },
//   ];

//   const format = [
//     { name: "DDMMYYYY", code: "NY" },
//     { name: "MMDDYYYY", code: "RM" },
//     { name: "YYYYMMDD", code: "LDN" },
//   ];

//   const separator = [
//     { name: "-", code: "NY" },
//     { name: "/", code: "RM" },
//     { name: ".", code: "LDN" },
//   ];

//    return (
//     <div>
//       <TabView>
//         <TabPanel header="Validation">
//           <div className="flex justify-content-between align-items-center">
//             Mandatory{" "}
//             <InputSwitch
//               checked={inputSwitchState}
//               onChange={(e) => handleInputChange(id, e)}
//             />
//           </div>
//           <br></br>


//           <div className="flex justify-content-between align-items-center mt-3">
//             Date Type{" "}
//             <Dropdown
//               style={{ height: "40px", width: "60%" }}
//               value={dropdownState}
//               onChange={(e) => handleDropdownChange(id, e)}
//               options={types}
//               optionLabel="name"
//               placeholder="Select Type"
//             />
//           </div>

//        {dropdownState && dropdownState.code !== "MM" && dropdownState.code !== "LDN" && (
//             <div className="flex justify-content-between align-items-center mt-3">
//               Date Format{" "}
//               <Dropdown
//                 style={{ height: "40px", width: "60%" }}
//                 value={dropdownState1}
//                 onChange={(e) => handleDropdownChange1(id, e)}
//                 options={format}
//                 optionLabel="name"
//                 placeholder="Select Type"
//               />
//             </div>
//           )}

//           {dropdownState && dropdownState.code !== "MM" && dropdownState.code !== "LDN" && (
//             <div className="flex justify-content-between align-items-center mt-3">
//               Date Separator{" "}
//               <Dropdown
//                 style={{ height: "40px", width: "60%" }}
//                 value={dropdownState2}
//                 onChange={(e) => handleDropdownChange2(id, e)}
//                 options={separator}
//                 optionLabel="name"
//                 placeholder="Select Type"
//               />
//             </div>
//           )}

//             {dropdownState && dropdownState.code === "MM" ? null : (
//             <>
//               <div className="flex justify-content-between align-items-center mt-3">
//                 {dropdownState && dropdownState.code === "LDN"
//                   ? "Min. Year"
//                   : "Min. Date"}
//                 <Calendar
//                   style={{ height: "40px", width: "60%" }}
//                   value={date}
//                   onChange={(e) => setContextDate(e.value)}
//                   showIcon
//                   dateFormat={
//                     dropdownState && dropdownState.code === "LDN"
//                       ? "yy"
//                       : "mm/dd/yy"
//                   }
//                   view={
//                     dropdownState && dropdownState.code === "LDN"
//                       ? "year"
//                       : "date"
//                   }
//                 />
//               </div>

//               <div className="flex justify-content-between align-items-center mt-3">
//                 {dropdownState && dropdownState.code === "LDN"
//                   ? "Max. Year"
//                   : "Max. Date"}
//                 <Calendar
//                   style={{ height: "40px", width: "60%" }}
//                   value={date1}
//                   onChange={(e) => setContextDate1(e.value)}
//                   showIcon
//                   dateFormat={
//                     dropdownState && dropdownState.code === "LDN"
//                       ? "yy"
//                       : "mm/dd/yy"
//                   }
//                   view={
//                     dropdownState && dropdownState.code === "LDN"
//                       ? "year"
//                       : "date"
//                   }
//                 />
//               </div>
//             </>
//           )}

//        <div className="flex justify-content-between mt-5">
//        <Button className="p-2" severity="success" >Duplicate Question</Button>
//        <Button className="p-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>

//        </div>
//         </TabPanel>
//         <TabPanel header="Skip Logic">
//           <p className="m-0">Skip Logic</p>
//         </TabPanel>
//       </TabView>
//     </div>
//   );
// };

// export default DateRightBarContent;



import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

const DateRightBarContent = ({
  id,
  inputSwitchState,
  handleInputChange,
  handleDelete,
  minDate,
  maxDate,
  handleMinDateChange,
  handleMaxDateChange,
  handleDuplicate
}) => {



  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 100;

   return (
    <div>
      <TabView>
        <TabPanel header="Validation">
          <div className="flex justify-content-between align-items-center">
            Mandatory{" "}
            <InputSwitch
              checked={inputSwitchState}
              onChange={(e) => handleInputChange(id, e)}
            />
          </div>
          <br></br>

              <div className="flex justify-content-between align-items-center mt-3">
                  Min. Date
                <Calendar
                  style={{ height: "40px", width: "70%" }}
                  value={minDate[id]}
                  onChange={(e) => handleMinDateChange(id, e.value)}
                  showIcon
                  dateFormat="dd/mm/yy"
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date(maxYear, 11, 31)}
                />
              </div>

              <div className="flex justify-content-between align-items-center mt-3">
                   Max. Date
                <Calendar
                  style={{ height: "40px", width: "70%" }}
                  value={maxDate[id]}
                  onChange={(e) => handleMaxDateChange(id, e.value)}
                  showIcon
                  dateFormat="dd/mm/yy"
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date(maxYear, 11, 31)}
                />
              </div>

       <div className="flex justify-content-between mt-5">
       <Button className="p-2" severity="success" onClick={() => handleDuplicate(id)}>Duplicate Question</Button>
       <Button className="p-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>

       </div>
        </TabPanel>
        <TabPanel header="Skip Logic">
          <p className="m-0">Skip Logic</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default DateRightBarContent;
