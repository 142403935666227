import React from 'react'
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";

const CheckboxRightBarContent = ({
    id,
    inputSwitchState,
    inputSwitchState3,
    dropdownState1,
    handleInputChange,
    handleInputChange3,
    handleDropdownChange1,
    handleDelete,
    handleDuplicate
}) => {
    const types = [
        { name: "Single Selection", code: "1" },
        { name: "Multi Selection", code: "2" },
      ];
      return (
        <div>
          <TabView>
            <TabPanel header="Validation">
              <div className="flex justify-content-between align-items-center">
                Mandatory{" "}
                <InputSwitch
                  checked={inputSwitchState}
                  onChange={(e) => handleInputChange(id, e)}
                />
              </div>
    
              <div className="flex justify-content-between align-items-center mt-3">
              Others (Specify){" "}
                <InputSwitch
                  checked={inputSwitchState3}
                  onChange={(e) => handleInputChange3(id, e)}
                />
              </div>
              <br></br>


              <div className="flex justify-content-between align-items-center mt-3">
                Selection{" "}
                <Dropdown
                  style={{ height: "40px", width: "60%" }}
                  value={dropdownState1}
                  onChange={(e) => handleDropdownChange1(id, e)}
                  options={types}
                  optionLabel="name"
                  placeholder="Select type"
                />
              </div>

           <div className="flex justify-content-around mt-5">
           <Button className="p-2" severity="success" onClick={() => handleDuplicate(id)}>Duplicate Question</Button>
           <Button className="p-2 ml-2" severity="danger"onClick={() => handleDelete(id)}>Delete Question</Button>

           </div>
            </TabPanel>
            <TabPanel header="Skip Logic">
              <p className="m-0">Skip Logic</p>
            </TabPanel>
          </TabView>
        </div>
   )
}

export default CheckboxRightBarContent