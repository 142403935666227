import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'

const Checkbox = ({ id, onInputChange, onOptionsChange, txtQuestion}) => {
  const [inputs, setInputs] = useState([{ id: 1, value: '' }]);
  const [options, setOptions] = useState('');

  useEffect(() => {
    const concatenatedValues = inputs.map((input) => input.value.trim()).join('$$');
    console.log(`Checkbox ID: ${id}, Options: ${concatenatedValues}`);

    setOptions(concatenatedValues);
    onOptionsChange(id, concatenatedValues);
  }, [inputs, id, onOptionsChange]);

  const handleInputChange = (inputId, value) => {
    const updatedInputs = inputs.map((input) =>
      input.id === inputId ? { ...input, value } : input
    );
    setInputs(updatedInputs);
  };

  const handleAddInput = () => {
    const newId = inputs.length > 0 ? inputs[inputs.length - 1].id + 1 : 1;
    setInputs([...inputs, { id: newId, value: '' }]);
  };

  const handleDeleteInput = (inputId) => {
    const updatedInputs = inputs.filter((input) => input.id !== inputId);
    setInputs(updatedInputs);
  };

  return (
    <div className="flex justify-content-center mt-4">
      <div
        style={{
          width: "95%",
          background: "#fff",
          borderRadius: "12px",
          border: "1px solid #11409E",
        }}
      >
        <div className="flex justify-content-center">
          <div
            className="flex align-items-center justify-content-center m-1"
            style={{
              width: "100%",
              height: "4rem",
              background: "#fff",
            }}
          >
            <img src="select_check_box.png" alt="logo" className=" mr-2" style={{ width: "32px" }} />
            <InputText
              placeholder="Enter Checkbox Question here..."
              style={{ width: "90%", border: "none" }}
              onChange={(e) => onInputChange(id, e.target.value)}
              value={txtQuestion}
            />
          </div>
        </div>

        <div style={{ marginLeft: "4%" }}>
          {inputs.map((input) => (
            <div
              key={input.id}
              style={{
                width: '95%',
                height: '4rem',
                background: '#fff',
              }}
            >
              <InputText
                placeholder="Enter checkbox Options"
                style={{ width: '45%', height: "40px" }}
                value={input.value}
                onChange={(e) => {
                  handleInputChange(input.id, e.target.value);
                }}
              />
              <i className="pi pi-trash ml-5" onClick={() => handleDeleteInput(input.id)} style={{ color: "red" }}></i>
            </div>
          ))}
          <Button className="mb-4 formbutton" onClick={handleAddInput}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
